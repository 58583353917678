<template>
  <v-card class="card--shadow">
    <v-card-title class="purple accent-4">
      <div class="subtitle-1 white--text">HE Activity Form</div>
    </v-card-title>
    <v-card-text class="pt-4">
      <form-require-noti />
      <v-row>
        <v-col lg="6">
          <label>Date <require-sign /></label>
          <!-- <date-picker
            @clearDate="heData.date = null"
            :validationMsg="handleFieldErrors($v.heData.date)"
            :date="heData.date"
            v-model="heData.date"
            :min="minDate"      
            :max="maxDate"
          /> -->
            <date-picker :date="heData.date" v-model="heData.date"/>
        </v-col>
        <v-col lg="6">
          <label>Place <require-sign /></label>
          <v-text-field
            v-model.trim="heData.place"
            :error-messages="handleFieldErrors($v.heData.place)"
            type="text"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="6">
          <label>Type <require-sign /></label>
          <v-select
            :items="[
              { id: '1', text: 'Community' },
              { id: '2', text: 'Contact' },
            ]"
            v-model="heData.type"
            :error-messages="handleFieldErrors($v.heData.type)"
            item-text="text"
            item-value="id"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col lg="6">
          <label>Screening Person <require-sign /></label>
          <v-select
            :items="[
              { id: '1', text: 'Volunteer' },
              { id: '2', text: 'FO' },
            ]"
            v-model="heData.screening_person"
            :error-messages="handleFieldErrors($v.heData.screening_person)"
            item-text="text"
            item-value="id"
            dense
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row v-if="heData.screening_person == 1">
        <!-- <v-col lg="4">
            <label>Outside Township</label>
            <v-radio-group  v-model="heData.outside_township"  row>
              <v-radio label="Yes" value=1></v-radio>
              <v-radio label="No" value=0></v-radio>
            </v-radio-group>

          </v-col> --> 
        <v-col lg="6">
          <label>Supporting Township</label>
          <v-select
            :items="townships"
            v-model="heData.supporting_township_id"
            @change="handleSupportingTownship"
            item-text="name"
            item-value="id"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col lg="6">
          <label>Volunteers</label>
          <v-select
            :items="volunteers"
            v-model="heData.volunteer_id"
            :error-messages="handleFieldErrors($v.heData.volunteer_id)"
            item-value="id"
            item-text="name"
            dense
            outlined
          >
            <template v-slot:item="{ item }">
              <span>{{ item.name }} / {{ item.code }}</span>
            </template>
            <template v-slot:selection="{ item }">
              <span>{{ item.name }} / {{ item.code }}</span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row v-if="heData.type == 2">
        <v-col md="4">
          <label>Index Case No <require-sign /></label>
          <v-text-field
            v-model.trim="heData.index_case_no"
            :error-messages="handleFieldErrors($v.heData.index_case_no)"
            type="number"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col md="4">
          <label>Index Case Year <require-sign /></label>
          <v-text-field
            v-model.trim="heData.index_case_year"
            :error-messages="handleFieldErrors($v.heData.index_case_year)"
            type="number"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col md="4">
          <label>Mono-Poly/SG <require-sign /></label>
          <v-select
              v-model="heData.index_case_monopoly"
              :items="monopoly"
              :error="$v.heData.index_case_monopoly"
              item-text="text"
              item-value="id"
              dense
              outlined
            ></v-select>
        </v-col>
        
        <!-- <v-col md="6" v-show="admin.township.initial == 2">
          <label>Initial</label>

          <v-select
            v-model="heData.index_case_initial"
            :items="InitialOpt"
            item-text="text"
            item-value="value"
            dense
            outlined
          ></v-select>
        </v-col> -->
      </v-row>

      <v-row v-if="heData.type == 1">
        <v-col lg="6">
          <label>Attendence Male </label>
          <v-text-field
            v-model.trim="heData.n_o_attendence_male"
            :error-messages="handleFieldErrors($v.heData.n_o_attendence_male)"
            type="number"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col lg="6">
          <label>Attendence Female </label>
          <v-text-field
            v-model.trim="heData.n_o_attendence_female"
            :error-messages="handleFieldErrors($v.heData.n_o_attendence_female)"
            type="number"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="heData.type == 2">
        <v-col lg="6">
          <label>Household Male </label>
          <v-text-field
            v-model.trim="heData.n_o_household_male"
            :error-messages="handleFieldErrors($v.heData.n_o_household_male)"
            type="number"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col lg="6">
          <label>Household Female </label>
          <v-text-field
            v-model.trim="heData.n_o_household_female"
            :error-messages="handleFieldErrors($v.heData.n_o_household_female)"
            type="number"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="heData.type == 2">
        <v-col lg="6">
          <label>Close Contact Male </label>
          <v-text-field
            v-model.trim="heData.n_o_closecontact_male"
            :error-messages="handleFieldErrors($v.heData.n_o_closecontact_male)"
            type="number"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col lg="6">
          <label>Close Contact Female</label>
          <v-text-field
            v-model.trim="heData.n_o_closecontact_female"
            :error-messages="
              handleFieldErrors($v.heData.n_o_closecontact_female)
            "
            type="number"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row >
        <v-col lg="6">
          <label>Refer Male</label>
          <v-text-field
            v-model.trim="heData.refer_male"
            :error-messages="handleFieldErrors($v.heData.refer_male)"
            type="number"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col lg="6">
          <label>Refer Female</label>
          <v-text-field
            v-model.trim="heData.refer_female"
            :error-messages="handleFieldErrors($v.heData.refer_female)"
            type="number"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col lg="8">
          <label>Remark</label>
          <v-text-field
            v-model.trim="heData.remark"
            type="text"
            dense
            filled
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="px-4 pb-4">
      <v-spacer></v-spacer>
      <v-btn @click.prevent="emitData" :loading="loading" class="form-btn">
        <v-icon left>mdi-chevron-right-circle-outline</v-icon>Submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { required, between } from "vuelidate/lib/validators";
import { ValidationMixin } from "../../mixins/ValidationMixin";
import { limitDateMixin } from "../../mixins/limitDateMixin";
import { mapState, mapActions } from "vuex";
import FormRequireNoti from "@/components/notification/FormRequireNoti.vue";
import RequireSign from "@/components/notification/RequireSign.vue";
import DatePicker from "@/components/common/DatePicker.vue";
export default {
  components: { RequireSign, FormRequireNoti, DatePicker },
  mixins: [ValidationMixin, limitDateMixin],
  props: {
    heData: {
      type: Object,
      default: () => {
        return {
          type: null,
          screening_person:null,
          refer_male:null,
          refer_female:null,
          date: null,
          place: null,
          n_o_attendence_male: null,
          n_o_attendence_female: null,
          n_o_household_male: null,
          n_o_household_female: null,
          n_o_closecontact_male:null,
          n_o_closecontact_female:null,
          index_case_monopoly:1,
          volunteer_id: null,
          index_case_year: null,
          index_case_no: null,
          index_case_initial: null,
          remark: null,
        };
      },
    },
  },
  data: () => ({
    menu2: false,
    InitialOpt: [
      { value: "M", text: "M" },
      { value: "", text: "" },
    ],
    supporting_township_id: null,
  }),
  beforeMount() {
    this.heData.date = !this.heData.date ? this.minDate : this.heData.date;
  },
  validations() {
    return {
      heData: {
        type: { required },
        date: { required },
        place: { required },
        screening_person:{ required},
        index_case_year: {
          required: (value) => {
            if (this.heData.type == 1 && value == null) {
              return true;
              // return !helpers.req(value) || true;
            }
            //? vuelidate helpers object make false to all propertries
            //? that include in current object (eg - index_case_year)
              return true;
          },
        },
        // index_case_monopoly: {
        //   required: (value) => {
        //     if (this.heData.type == 1 && value == null) {
        //       return true;
        //     }
         
        //       return false;
        //   },
        // },
        index_case_no: {
          required: (value) => {
            if (this.heData.type == 1 && value == null) {
              return true;
              // return !helpers.req(value) || true;
            }
            return true;
          },
        },
        volunteer_id: { 
          required: (value) => {
            if (this.heData.screening_person === 1 && value == null) {
              return true;
             
            }
            return true;
          },
         },
        n_o_attendence_male: {between: between(0, 99) },
        n_o_attendence_female: {between: between(0, 99) },
        n_o_household_male: { between: between(0, 99) },
        n_o_household_female: { between: between(0, 99) },
        n_o_closecontact_male:{between: between(0, 99) },
        n_o_closecontact_female:{between: between(0, 99) },
        refer_male: { between: between(0, 99) },
        refer_female: { between: between(0, 99) },
      },
    };
  },
  methods: {
    ...mapActions("InfoData", ["getAllTownships"]),
    handleSupportingTownship(id) {
      this.$store.dispatch("Volunteer/getVolunteerByTwnId", id);
    },
    emitData() {
      this.$v.$touch();
      console.log(this.$v);
      if (!this.$v.$invalid) {
        let data = {
          id: this.heData.id,
          type: this.heData.type,
          screening_person:this.heData.screening_person,
          supporting_township_id: this.heData.supporting_township_id?this.heData.supporting_township_id:0,
          date: this.heData.date,
          place: this.heData.place,
          refer_male:this.heData.refer_male,
          refer_female:this.heData.refer_female,
          n_o_attendence_female: this.heData.n_o_attendence_female,
          n_o_attendence_male: this.heData.n_o_attendence_male,
          n_o_household_female: this.heData.n_o_household_female,
          n_o_household_male: this.heData.n_o_household_male,
          n_o_closecontact_male:this.heData.n_o_closecontact_male,
          n_o_closecontact_female:this.heData.n_o_closecontact_female,
          volunteer_id: this.heData.volunteer_id,
          index_case_initial: this.heData.index_case_initial,
          index_case_no: this.heData.index_case_no,
          index_case_year: this.heData.index_case_year,
          index_case_monopoly: this.heData.index_case_monopoly?this.heData.index_case_monopoly:1,
          month: this.$route.params.month,
          year: this.$route.params.year,
          remark: this.heData.remark,
        };
        console.log(data);
        this.$emit("emitData", data);
      }
    },
  },
  computed: {
    ...mapState("Loading", ["loading"]),
    ...mapState("Volunteer", ["volunteers"]),
    ...mapState("Admin", ["admin"]),
    ...mapState("InfoData", ["townships","monopoly"]),
  },
  
  created() {
    //this.$store.dispatch("Volunteer/getVolunteers");
    this.$store.dispatch(
      "Volunteer/getVolunteerByTwnId",
      this.admin.township_id,
    );
    this.getAllTownships();
  },
};
</script>

<style scoped></style>
