<template>
  <div>
    <back-btn />
    <v-container fluid>
      <v-row justify="center">
        <v-col lg="8">
          <v-card class="card--shadow">
            <he-form @emitData="createHe" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HeForm from "@/components/HE/HeForm";
import BackBtn from "@/components/navigation/BackBtn.vue";

export default {
  components: {
    HeForm,
    BackBtn,
  },
  methods: {
    createHe(data) {
      console.log(data);
      this.$store.dispatch("HE/create", data).then(() => {
        this.$router.push(
          `/he/activity/${this.$route.params.month}/${this.$route.params.year}`
        );
      });
    },
  },
};
</script>
